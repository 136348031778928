import logo from './logo.svg';
import './App.css';
import PageList from './Components/PageList';


function App() {
  return (
    <div className="App">
      <PageList/>
    </div>
  );
}

export default App;
