import React, { useState, useRef, useEffect } from 'react';
import './VideoCall.css';
import { IoSend } from "react-icons/io5";
import { useParams } from 'react-router-dom';
import { LiaMicrophoneSlashSolid, LiaMicrophoneSolid } from "react-icons/lia";
import { FiVideoOff, FiVideo } from "react-icons/fi";
import { PiSubtitlesLight, PiSubtitlesSlash } from "react-icons/pi";
import { FcEndCall } from "react-icons/fc";
import RecordRTC from 'recordrtc';
import io from 'socket.io-client';

const VideoCall = () => {
    const [isMuted, setIsMuted] = useState(false);
    const [isVideoOn, setIsVideoOn] = useState(true);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [activeTab, setActiveTab] = useState('Chat');

    const [remoteUserName, setRemoteUserName] = useState();

    const localVideoRef = useRef(null);
    const remoteVideoRef = useRef(null);
    const localStreamRef = useRef(null);

    const socketRef = useRef(null);
    const pcRef = useRef(null);
    const params = useParams();
    const userName = params.username;
    const roomName = params.room;
    const [participants, setParticipants] = useState([
        { username: userName, id: 1 }
    ]);

    const [transcription, setTranscription] = useState([]);
    const [recording, setRecording] = useState(false);
    const [recordAudio, setRecordAudio] = useState(null);

    const sendData = (data) => {
        socketRef.current.emit('data', {
            username: userName,
            room: roomName,
            data: data,
        });
    };

    const startRecording = () => {
        if (localStreamRef.current) {
            const recorder = RecordRTC(localStreamRef.current, {
                type: 'audio',
                mimeType: 'audio/webm',
                sampleRate: 44100,
                desiredSampRate: 16000,
                recorderType: RecordRTC.StereoAudioRecorder,
                numberOfAudioChannels: 1,
                timeSlice: 1000, // Send audio data every second
                ondataavailable: (blob) => {
                    blob.arrayBuffer().then(buffer => {
                        socketRef.current.emit('audio', buffer);
                    });
                }
            });

            recorder.startRecording();
            setRecordAudio(recorder);
            setRecording(true);
            socketRef.current.emit('start_audio', {
                config: {
                    audio: {
                        encoding: 'LINEAR16',
                        sampleRateHertz: 16000,
                        languageCode: 'en-US'
                    },
                    interimResults: true
                },
                roomname: roomName,
                username: userName
            });
        }
    };

    const stopRecording = () => {
        if (recordAudio) {
            recordAudio.stopRecording(() => {
                setRecording(false);
                socketRef.current.emit('stop_audio');
            });
        }
    };

    useEffect(() => {
        startConnection();

        socketRef.current.on('speechData', (data) => {
            console.log("Transcription received:", data.data);
            setTranscription((prev) =>
                [...prev, { serverUserName: data.data.username, text: data.data.text }]
            );
        });

        socketRef.current.on('message',(data)=>{
            console.log("Message has been received:",data['message'])
            setTranscription((prev)=> [...prev, { serverUserName: data['username'], room: data['room'], text: data['message'] }])
        })
        return function cleanup() {
            if (pcRef.current) {
                pcRef.current.close();
            }
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, []);

    const startConnection = () => {
        socketRef.current = io("https://translation-api.harriersys.com");
        // socketRef.current = io("http://localhost:8000");

        socketRef.current.on("connect", () => {
            socketRef.current.emit("join", { username: userName, room: roomName });
        });

        socketRef.current.on("ready", (data) => {
            console.log("Ready to connect", data);
            data['remoteUserName'] !== userName && setRemoteUserName(data['remoteUserName']);

            if (!pcRef.current) {
                navigator.mediaDevices
                    .getUserMedia({ audio: true, video: true }) // Get both audio and video
                    .then((stream) => {
                        console.log("Local stream found");
                        localStreamRef.current = stream;
                        localVideoRef.current.srcObject = stream;
                        createPeerConnection(stream);
                    })
                    .catch((error) => {
                        console.error("Stream not found: ", error);
                    });
            } else {
                sendOffer();
            }
        });

        socketRef.current.on("data", (data) => {
            console.log("Data received: ", data);
            signalingDataHandler(data);
        });

        socketRef.current.on("error", (error) => {
            console.error("Socket error: ", error);
        });
    };

    const onIceCandidate = (event) => {
        if (event.candidate) {
            console.log("Sending ICE candidate");
            sendData({
                type: "candidate",
                candidate: event.candidate,
            });
        }
    };

    const onTrack = (event) => {
        console.log("Adding remote track");
        remoteVideoRef.current.srcObject = event.streams[0];
    };

    const createPeerConnection = (stream) => {
        try {
            const peerConnection = new RTCPeerConnection({
                // iceServers: [ 
                //     { urls: "stun:openrelay.metered.ca:80" },
                //     {
                //         urls: "turn:openrelay.metered.ca:80",
                //         username: "openrelayproject",
                //         credential: "openrelayproject",
                //     },
                //     {
                //         urls: "turn:openrelay.metered.ca:443",
                //         username: "openrelayproject",
                //         credential: "openrelayproject",
                //     },
                //     {
                //         urls: "turn:openrelay.metered.ca:443?transport=tcp",
                //         username: "openrelayproject",
                //         credential: "openrelayproject",
                //     },
                // ],
                iceServers: [
                    {
                      urls: 'stun:stun.l.google.com:19302'
                    }
                  ]
            });

            peerConnection.onicecandidate = onIceCandidate;
            peerConnection.ontrack = onTrack;

            for (const track of stream.getTracks()) {
                peerConnection.addTrack(track, stream);
            }

            pcRef.current = peerConnection;
            console.log("Peer connection created");

            sendOffer();
        } catch (error) {
            console.error("Failed to create peer connection: ", error);
        }
    };

    const setAndSendLocalDescription = (sessionDescription) => {
        pcRef.current.setLocalDescription(sessionDescription)
            .then(() => {
                console.log("Local description set");
                sendData(sessionDescription);
            })
            .catch((error) => {
                console.error("Failed to set local description: ", error);
            });
    };

    const sendOffer = () => {
        console.log("Sending offer");
        pcRef.current.createOffer()
            .then(setAndSendLocalDescription)
            .catch((error) => {
                console.error("Failed to send offer: ", error);
            });
    };

    const sendAnswer = () => {
        console.log("Sending answer");
        pcRef.current.createAnswer()
            .then(setAndSendLocalDescription)
            .catch((error) => {
                console.error("Failed to send answer: ", error);
            });
    };

    const signalingDataHandler = (data) => {
        if (!pcRef.current) {
            console.error("Peer connection not initialized");
            return;
        }
        console.log("Signaling data received: ", data);
        if (data.type === "offer") {
            console.log("Offer received");
            pcRef.current.setRemoteDescription(new RTCSessionDescription(data))
                .then(sendAnswer)
                .catch((error) => console.error("Failed to set remote description: ", error));
        } else if (data.type === "answer") {
            console.log("Answer received");
            pcRef.current.setRemoteDescription(new RTCSessionDescription(data))
                .catch((error) => console.error("Failed to set remote description: ", error));
        } else if (data.type === "candidate") {
            console.log("Candidate received");
            pcRef.current.addIceCandidate(new RTCIceCandidate(data.candidate))
                .catch((error) => console.error("Failed to add ICE candidate: ", error));
        } else {
            console.log("Unknown data: ", data);
        }
    };

    const handleMuteClick = () => {
        if (localStreamRef.current) {
            localStreamRef.current.getAudioTracks().forEach(track => {
                track.enabled = isMuted;
            });
        }
        setIsMuted(!isMuted);
    };

    const handleVideoToggleClick = () => {
        setIsVideoOn(!isVideoOn);
        if (localStreamRef.current) {
            localStreamRef.current.getVideoTracks().forEach(track => {
                track.enabled = isVideoOn;
            });
        }
    };

    const handleLeaveClick = () => {
        // Stop all tracks and release user media
        if (localStreamRef.current) {
            localStreamRef.current.getTracks().forEach(track => track.stop());
        }
        console.log('Leave call');
    };

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            socketRef.current.emit("message", { username: userName, room: roomName, text: newMessage });
            // setTranscription([...transcription, { serverUserName: userName, room: roomName, text: newMessage }]);
            setNewMessage('');
        }
    };

    return (
        <div className="video-call-container">
            {/* Main video area */}
            <div className="main-video">
                <video ref={remoteVideoRef} className="remote-video" autoPlay></video>
                <div className="video-overlay">
                    <span>{remoteUserName}(Remote User)</span>
                </div>
            </div>

            {/* Local video in picture-in-picture mode */}
            <div className="local-video-container">
                <video ref={localVideoRef} className="local-video" autoPlay muted></video>
                <div className="video-overlay-local">
                    <span>{userName}(You)</span>
                </div>
            </div>

            {/* Bottom control bar */}
            <div className="bottom-bar">
                <button onClick={!recording ? startRecording : stopRecording}>
                    {!recording ? <PiSubtitlesLight size={18} /> : <PiSubtitlesSlash />}
                </button>
                <button className="control-button" onClick={handleMuteClick}>
                    {isMuted ? <LiaMicrophoneSlashSolid size={18} /> : <LiaMicrophoneSolid size={18} />}
                </button>
                <button className="control-button" onClick={handleVideoToggleClick}>
                    {isVideoOn ? <FiVideoOff /> : <FiVideo />}
                </button>
                <button className="control-button" onClick={handleLeaveClick}>
                    {<FcEndCall size={20} />}
                </button>
            </div>

            {/* Message input box */}
            <div className='side-panel'>
                <div className="top-bar">
                    <div
                        className={`tab ${activeTab === 'Chat' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Chat')}
                    >
                        Chat
                    </div>
                    <div
                        className={`tab ${activeTab === 'Participants' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Participants')}
                    >
                        Participants
                    </div>
                </div>

                {activeTab === 'Chat' && (
                    <div className="message-input-container">
                        <input
                            type="text"
                            className="message-input"
                            placeholder="Start a conversation"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                        />
                        <button className="send-button" onClick={handleSendMessage}>{<IoSend size={18} />}</button>
                    </div>
                )}

                {/* Chat box */}
                {activeTab === 'Chat' && (
                    <div className="chat-box">
                        {transcription && transcription.map((message, index) => (
                            <div key={index} className="chat-message">
                                <span className="chat-user">{message.serverUserName}: </span>
                                <span className="chat-text">{message.text}</span>
                            </div>
                        ))}
                    </div>
                )}

                {/* Participant list */}
                {activeTab === 'Participants' && (
                    <div className="participant-list">
                        {participants.map((participant) => (
                            <div key={participant.id} className="participant">
                                <span className="participant-icon">👤</span>
                                <span className="participant-name">{participant.username}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default VideoCall;
