import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
// import ConnectionScreen from './ConnectionStream';
import ConnectionScreen from './conStream';
import Lobby from './Lobby';
import VideoCall from './VideoScreen';


export default function PageList() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route exact
                        path="/"
                        element={<Lobby />} />

                    <Route exact
                    path="/call/:username/:room"
                    element={<VideoCall/>} />
                </Routes>
            </BrowserRouter>
        </div>


    )
};
