import React, { useState, useRef, useEffect } from 'react';
import './CSS/Mute.css';
import { FaMicrophoneAltSlash,FaMicrophoneAlt} from "react-icons/fa";
import { FiVideoOff,FiVideo } from "react-icons/fi";
import { FaUserDoctor } from "react-icons/fa6";
import { Link } from 'react-router-dom';


const Lobby = () => {
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [isAudioOn, setIsAudioOn] = useState(false);
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const [username,setUserName] = useState()
  const [roomname,setRoomName] = useState()

  useEffect(() => {
    const getMedia = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        streamRef.current = stream;
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error("Error accessing media devices.", err);
      }
    };
    getMedia();
  }, []);

  const toggleVideo = () => {
    if (streamRef.current) {
      const videoTrack = streamRef.current.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = !videoTrack.enabled;
        setIsVideoOn(videoTrack.enabled);
      }
    }
  };

  const toggleAudio = () => {
    if (streamRef.current) {
      const audioTrack = streamRef.current.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !audioTrack.enabled;
        setIsAudioOn(audioTrack.enabled);
      }
    }
  };

  return (
    <div className="web-page">
      <h1 className="title">Get Started</h1>
      <p className="subtitle">Setup your audio and video before joining</p>
      <div className="message">You are the first to join</div>
      <div className="video-container">
        <video ref={videoRef} autoPlay muted className={isVideoOn ? 'video-on' : 'video-off'} />
        {!isVideoOn && (
          <div className="user-icon">
            <div className="icon"><FaUserDoctor/></div>
          </div>
        )}
        <button className="mute-button" onClick={toggleVideo}>
          {isVideoOn ? <FiVideo /> :<FiVideoOff />}
        </button>
      </div>
      <div className="controls">
        <button className="control-button" onClick={toggleAudio}>
          {isAudioOn ? <FaMicrophoneAlt /> : <FaMicrophoneAltSlash />}
        </button>
        <button className="control-button" onClick={toggleVideo}>
          {isVideoOn ?  <FiVideo />  : <FiVideoOff />}
        </button>
    
      </div>
      <div className='Join-Class'>
        <input className="name-input" value={username} onInput={(e)=> setUserName(e.target.value)} type="text" placeholder="Enter name" />
        <input className="name-input" value ={roomname} onInput={(e)=> setRoomName(e.target.value)} type="text" placeholder="Enter Room Key" />
   
      </div>
      <Link to={`/call/${username}/${roomname}`}>
      <button className="join-button">Join Now</button>
      
      </Link>
    </div>
  );
};

export default Lobby;
